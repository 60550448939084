import { getProductSettingsByKeys } from '@/services/product/settings/productSettings';
import { formatRegionCode, isRegionFromCountry } from '@/utils/regionUtils';
import i18n from '@/i18n';

const sanitizeNextProductName = ({ regionCode, productName, lang }) => {
  const regionCodeLowerCase = regionCode.toLowerCase();
  const showWorldwideProductsOnly =
    !isRegionFromCountry(regionCodeLowerCase, 'RU') && !(regionCodeLowerCase === 'custom' && lang === 'ru');
  const nextProductSettings = getProductSettingsByKeys({ keys: [productName], i18n })[0];
  if (nextProductSettings) return showWorldwideProductsOnly && !nextProductSettings.isWorldwide ? 'base' : productName;

  return 'base';
};

const getProductRoute = ({ productSettings, regionCode, productName, queryParams = {} }) => {
  if (productSettings?.to?.name)
    return {
      name: productSettings.to.name,
      params: {
        regionCode,
        productId: productName,
      },
      query: queryParams,
    };

  return {
    name: 'base',
    params: {
      regionCode,
    },
    query: queryParams,
  };
};

const getNextProductRoute = ({ regionCode, route, query = {}, nextProductName }) => {
  const { 'next-product': nextProductQuery } = route.query || {};

  const nextProductNameSanitized = sanitizeNextProductName({
    regionCode,
    productName: nextProductName || nextProductQuery,
    lang: route.params.lang,
  });
  return getProductRoute({
    productSettings: getProductSettingsByKeys({ keys: [nextProductNameSanitized], i18n })[0],
    regionCode: formatRegionCode(regionCode),
    productName: nextProductNameSanitized,
    queryParams: { ...query },
  });
};

const getProductNameFromRoute = (route) => {
  let productName = ['base', 'dem', 'msbld', 'msrd', 'simpleDataProduct'].includes(route.name) ? route.name : 'base';
  if (productName === 'simpleDataProduct') productName = route.params.productId;
  return productName;
};

export { getProductRoute, getProductNameFromRoute, sanitizeNextProductName, getNextProductRoute };
