const formatRegionCode = (regionCode) => (regionCode.toLowerCase() === 'custom' ? 'custom' : regionCode.toUpperCase());

const isCustomRegion = (regionCode) => regionCode.toLowerCase() === 'custom';

const isRegionFromCountry = (regionCode, countryCode) => {
  const countryCodeLowerCased = countryCode.toLowerCase();
  const regionCodeStartsWith = regionCode.toLowerCase().slice(0, 3);
  return regionCodeStartsWith === `${countryCodeLowerCased}` || regionCodeStartsWith === `${countryCodeLowerCased}-`;
};

export { formatRegionCode, isCustomRegion, isRegionFromCountry };
