import { HOST } from '@/config';
import { formatRegionCode } from '@/utils/regionUtils';

const getCatalogCanonicalUrl = ({ lang, level, country }) => {
  const countrySuffix = level === 'countries' ? '' : `?country=${country}`;
  return `${HOST}${lang}/catalog/${level}/${countrySuffix}`;
};

const getProductCanonicalUrl = ({ lang, regionCode, productId }) =>
  `${HOST}${lang}/region/${formatRegionCode(regionCode)}/${productId}/`;

async function tryScrollToAnchor(hash, timeout = 1000, delay = 100) {
  const wait = (duration) => {
    return new Promise((resolve) => {
      setTimeout(resolve, duration);
    });
  };

  while (timeout > 0) {
    const el = document.querySelector(hash);
    if (el) {
      el.scrollIntoView();
      break;
    }
    // eslint-disable-next-line no-await-in-loop
    await wait(delay);
    // eslint-disable-next-line  no-param-reassign
    timeout -= delay;
  }
}

export { getCatalogCanonicalUrl, getProductCanonicalUrl, tryScrollToAnchor };
