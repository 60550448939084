import { memoize } from 'lodash-es';
import * as regionApi from '@/api/regionApi';
import * as nominatimApi from '@/api/nominatimApi';
import { convertRegionDataToSearchResult, convertNominatimSearchItemToSearchResult } from '@/utils/regionSearchUtils';
import { areRoutesHaveSameRegions } from '@/utils/regionRouterUtils';
import { getNextProductRoute, getProductNameFromRoute } from '@/utils/productRouterUtils';

const NOMINATIM_RESULTS_LIMIT = 2;

const searchRegionsByQuery = (query, lang, options) =>
  Promise.all([
    regionApi.getRegionsByQuery({ query, lang, count: 5, options }),
    nominatimApi.getNominatinRegionsByQuery({ query, options }),
  ]).then(([regionSearchResponse, nominatimSearchResponse]) => {
    const regionSearchResults = regionSearchResponse
      ? regionSearchResponse.map((region) => convertRegionDataToSearchResult(region))
      : [];
    const nominatimSearchResults =
      nominatimSearchResponse && nominatimSearchResponse.features
        ? nominatimSearchResponse.features.reduce((acc, nominatimFeature) => {
            // only features with osm_type=relation are added
            return (nominatimFeature.properties.osm_type === 'relation' ||
              nominatimFeature.properties.osm_type === 'way') &&
              acc.length < NOMINATIM_RESULTS_LIMIT
              ? [...acc, convertNominatimSearchItemToSearchResult(nominatimFeature.properties)]
              : acc;
          }, [])
        : [];
    return [...regionSearchResults, ...nominatimSearchResults];
  });

const memoizedSearchRegionsByQuery = memoize(searchRegionsByQuery);

const goToProductFromSearch = ({ route, router, regionFromSearch }) => {
  const nextProductName = route.query['next-product'] || getProductNameFromRoute(route);
  let nextRoute;
  if (nextProductName) {
    if (regionFromSearch.source === 'catalog') {
      nextRoute = getNextProductRoute({
        regionCode: regionFromSearch.regionData.code,
        route,
        nextProductName,
      });
    } else {
      nextRoute = getNextProductRoute({
        regionCode: 'custom',
        route,
        query: { osmId: regionFromSearch.regionData.osmId },
        nextProductName,
      });
    }
  } else {
    console.warn('nextProductName is undefined');
  }
  if (nextRoute && !areRoutesHaveSameRegions(nextRoute, route)) router.push(nextRoute);
};

export { searchRegionsByQuery, memoizedSearchRegionsByQuery, goToProductFromSearch };
